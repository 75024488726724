import { paragonWidgetDataContext } from "@amzn/paragon-context/lib/ParagonWidgetDataContext";
import { SchemaCaseElementsPrefix } from "src/widgets/IssueSummaryWidget/model/schemaElementsData";
import { TestConstants } from "./testContants";
import { TestData } from "./testData";

export function installParagonWidgetDataContextProvider(el: HTMLElement) {
  const provider = paragonWidgetDataContext.createProvider(el);

  provider.value = {
    widgetId: "RequeueSnoozeWidgetId",
    widgetInstanceId: "RequeueSnoozeWidgetInstanceId",
    widgetVersion: "1",
    widgetState: {
      showIssueSummaryEnhancements: true
    },
    metaData: {
      title: "Case Details",
    },
  };

  provider.functions.proxyGet = async (dataOperation, params, options) => {
    switch (dataOperation) {
      default:
        throw `Unhandled dataOperation ${dataOperation}`;
    }
  };

  provider.functions.proxyPost = async (actionOperation, params, options) => {
    switch (actionOperation) {
    case "getSchemaElementsData":
        return {
            data: {
              schemaElementsData: {[SchemaCaseElementsPrefix.LLM_SCHEMA_PREFIX]: {
                schemaData: JSON.stringify({
                  PredictedText: "old pond / frog leaps in / water\'s sound",
                  PredictedTextTranslations: {
                    "ja_JP": "古池や蛙飛び込む水の音",
                    "zh_CN": "古老池塘的声音和青蛙跳入水中的声音"
                  },
                  Metadata: {
                    Identifiers: {
                        ASIN: Array(5).fill(TestConstants.asin),
                        OrderId: [TestConstants.orderId, TestConstants.orderId],
                        ShipmentId: Array(4).fill(TestConstants.shipmentId),
                    },
                    AttachmentResult: {
                      Attachments: [
                        TestData.attachmentSummary,
                        TestData.shorterAttachmentSummary,
                        TestData.attachmentSummaryWithError,
                        TestData.longAttachmentSummary
                      ],
                    },
                    Tickets: TestData.longTicketSummaries
                  },
                })
            }}} as any,
            success: true,
            status: 200,
        };
      case "predictSOPsFromCaseInfo":
        return {
          data: {
            sopList: [{id: 'test-sop-1', title: "Test SOP 1", description: "This is a test SOP"},
              {id: 'test-sop-2', title: "Test SOP 2", description: "This is a test SOP", flags: ["STEPPING_NOT_SUPPORTED"]},
            ]
          },
          success: true,
          status: 200
        }
      default:
        throw `Unhandled actionOperation ${actionOperation}`;
    }
  };
}
